@media only screen and (max-width: 575px) {
  .card-aviation-register {
    margin: 10px;
    max-width: 100%;
    padding: 20px 40px;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
  }

  .prefix-selector {
    width: 125px !important;
  }
}

@media only screen and (min-width: 576px) {
  .card-aviation-register {
    margin: 20px;
    max-width: 100%;
    padding: 20px 40px;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
  }
  
  .prefix-selector {
    width: 150px !important;
  }
}

@media only screen and (min-width: 768px) {
  .card-aviation-register {
    margin: 30px;
    max-width: 100%;
    padding: 20px 40px;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
  }
  
  .prefix-selector {
    width: 150px !important;
  }
}

@media only screen and (min-width: 992px) {
  .card-aviation-register {
    margin: 30px;
    max-width: 600px;
    padding: 20px 40px;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
  }
  
  .prefix-selector {
    width: 150px !important;
  }
}

.background-container {
  /* background-image: url('../../../assets/img/register/bg-bicca.png'), linear-gradient(180deg, #1F6FB8 0%, #2D3594 100%); */
  background-image: url('../../../assets/img/register/bg-bicca-v3.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 60% 60%;
  height: 92vh;
  overflow-y: auto; /* Enable vertical scrolling for the card */
}

.background-container-mobile {
  background-image: url('../../../assets/img/register/bg-bicca-mobile-v2.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-attachment: fixed;
  /* background-position: 0% 10%; */
  /* height: 35vh; */
}