.link-portfolio {
  cursor: pointer;
  color:  #848484 !important;
}

.link-sub-portfolio {
  cursor: pointer;
  color:  #848484 !important;
}

.link-portfolio:hover {
  text-decoration: underline !important;
  color: #0076de !important;
}

.link-sub-portfolio:hover {
  text-decoration: underline !important;
  color: #0076de !important;
}