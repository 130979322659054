.text-year {
  color: #32ab6d;
  font-style: italic;
}

.text-title {
  color: #32ab6d;
  font-style: normal;
  font-weight: bold;
}

.text-type {
  color: #848484;
  font-style: italic;
}

.dot {
  margin-right: -8px;
}

.text-loc {
  color: #848484;
  font-size: 11px;
}