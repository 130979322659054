
@media only screen and (max-width: 576px) {
  .header-job-preference {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 80px;
    padding: 0;
  }

  .footer-job-preference {
    background-color: #ffffff;
    position: fixed;
    z-index: 1; 
    width: 100%;
    height: 100px;
    left: 0;
    bottom: 0;
    padding: 0;
  }

  .footer-style {
    text-align: center;
  }

  .btn-skip {
    width: 100%;
  }
  
  .btn-next {
    width: 100%;
  }

  .btn-back {
    width: 100%;
  }
}

@media only screen and (min-width: 576px) {
  .header-job-preference {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 80px;
  }

  .footer-job-preference {
    background-color: #ffffff;
    position: fixed;
    z-index: 1; 
    width: 100%;
    height: 100px;
    left: 0;
    bottom: 0;
  }
  
  .footer-button {
    float: right;
  }

  .btn-skip {
    width: 130px;
  }
  
  .btn-next {
    width: 130px;
  }

  .btn-back {
    width: 130px;
  }
}

.card-selected {
  border: 2px solid #32ab6d;
}

.card-selected:hover {
  border: 2px solid #32ab6d;
}

.card-not-selected {
  border-radius: 6px;
}