@media only screen and (max-width: 576px) {
  .social-media {
    margin-top: 20px;
  }
}

@media only screen and (min-width: 576px) {
  .social-media {
    margin-top: 20px;
  }
}

@media only screen and (min-width: 768px) {
  .social-media {
    margin-top: 110px;
    float: right;
  }
}

@media only screen and (min-width: 1600px) {
  .social-media {
    margin-top: 110px;
    margin-right: -110px;
    float: right;
  }
}

.img-avatar {
  border-radius: 80px;
}