.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: #5f6163;
  font-size: 13px;
}
  
.col-img-job {
  display: flex; 
  justify-content: center;
  align-items: center;
}

.img-job {
  max-width: 100px; 
  max-height: 100px;
}

.title-job {
  color: #5f6163 !important;
  line-height: 1;
}

.title-job:hover {
  color: #32ab6d !important;
  text-decoration: underline !important;
  transition: 0.5s;
}

.company-job:hover {
  color: #32ab6d !important;
  transition: 0.5s;
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0px;
}

.add-filter {
  color: #5f6163;
}

.add-filter:hover {
  color: #32ab6d;
  transition: 0.5s;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: #32ab6d !important;
  background: #fff;
  border-color: #32ab6d;
}

.demo-infinite-container {
  height: 300px;
  padding: 8px 16px;
  overflow: auto;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
}