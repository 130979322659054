body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
  background-color: #fff;
}

.ant-input {
  border-radius: 6px;
}

.ant-picker {
  border-radius: 6px;
}

.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-radius: 6px;
  width: 99%;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 6px;
}

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 6px;
}

.ant-input-affix-wrapper {
  border-radius: 6px;
}

.ant-input-search-button {
  background-color:  #fff;
  color: #32ab6d;
}

.ant-select {
  border-radius: 6px;
}

.ant-select-selector {
  border-radius: 6px !important;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 6px;
}

.ant-input-group-addon {
  border-radius: 6px;
}

.ant-card-actions {
  border-radius: 6px;
}

.ant-input-group-addon 
  .ant-select.ant-select-single:not(.ant-select-customize-input) 
    .ant-select-selector {
      border-radius: 6px 0 0 6px;
    }

.ant-form-item-explain-error {
  font-size: 11px;
}

.logo {
  float: left;
  width: 140px;
  height: 52px;
  margin: 9px;
}

.logo-favicon {
  width: 40px;
  height: 40px;
}

.ant-row-rtl .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.margin-bottom {
  margin-bottom:  50px;
}

/* @media only screen and (min-width: 1200px) {
  .ant-layout-header {
    background: #fff;
    display: flex;
    justify-content: space-between;
    height: 50px;
    padding-left: 140px;
    padding-right: 160px;
  }
} */

/* @media only screen and (min-width: 992px) {
  .ant-layout-header {
    background: #fff;
    display: flex;
    justify-content: space-between;
    height: 50px;
    padding-left: 70px;
    padding-right: 120px;
  }
}

@media only screen and (min-width: 768px) {
  .ant-layout-header {
    background: #fff;
    display: flex;
    justify-content: space-between;
    height: 50px;
  }
}

@media only screen and (max-width: 768px) {
  .ant-layout-header {
    background: #fff;
    display: flex;
    justify-content: space-between;
    height: 50px;
  }
} */

.ant-layout-header {
  background: #fff;
  height: 50px;
  display: flex;
  justify-content: space-between;
  float: right;
  width: 100%;
  padding: 0;
}

.header-outside {
  background-color: #f1f3f6;
  /* box-shadow: 1px 4px 8px #e6e6e6; */
  position: sticky;
  width: 100%;
  z-index: 1;
  top: 0;
}

.header-inside {
  background-color: #f1f3f6;
  box-shadow: 1px 4px 8px #e6e6e6;
  /* position: sticky; */
  width: 100%;
  z-index: 1;
  top: 0;
  height: 70px;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 4px;
}

.header-mobile {
  padding: 0;
  padding-left: 8px;
  padding-right: 8px;
  height: auto;
}

.header-mobile-fixed {
  padding: 0;
  padding-left: 8px;
  padding-right: 8px;
  height: auto;
  z-index: 1;
  position: fixed;
}

.ant-list {
  background: #fff;
}

.ant-menu-horizontal {
  border-bottom: none;
}

.menu-mobile {
  color: black;
}

.menu-mobile-selected {
  color: #32ab6d;
}

.button-facebook {
  border-radius: 3px;
}

.button-google {
  border-radius: 3px;
}

.button-facebook:hover {
  color: #1877f2 !important;
  border: 1px solid #1877f2;
}

.button-google:hover {
  color: #ea4335 !important;
  border: 1px solid#ea4335;
}

.btn-info {
  background: #0085ff !important;
  color: #ffffff !important;
  border-color: #0085ff !important;
}

.btn-info:hover {
  background: #0069cc !important;
  color: #ffffff !important;
  border-color: #0069cc !important;
}

.loading-style {
  height: 100vh;
  text-align: center;
}

.width-100 {
  width: 100% !important;
}

.height-100 {
  height: 100% !important;
}

.box-shadow {
  box-shadow: 1px 1px 8px #cccccc;
}

.box-shadow-light {
  box-shadow: 1px 1px 12px #f2f2f2;
}

.width-auto {
  width: auto;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-color-white {
  background-color: #fff !important;
}

.bg-color-light {
  background-color: #fafafa !important;
}

.white-color {
  color: #fafafa !important;
}

.light-color {
  color: #848484 !important;
}

.dark-color {
  color: #5f6163 !important;
}

.info-color {
  color: #4272b9 !important;
}

.primary-color {
  color: #32ab6d !important;
}

.danger-color {
  color: #ff2424 !important;
}

.display-none {
  display: none !important;
}

.display-flex {
  display: flex;
}

.display-inline {
  display: inline;
}

.float-right {
  float: right !important;
}

.float-left {
  float: left !important;
}

.text-align-justify {
  text-align: justify !important;
}

.text-align-center {
  text-align: center !important;
}

.text-underline {
  text-decoration: underline;
}

.text-align-left {
  text-align: left !important;
}

.text-align-right {
  text-align: right !important;
}

.border-radius-3 {
  border-radius: 3px !important;
}

.border-radius-6 {
  border-radius: 6px !important;
}

.border-radius-8 {
  border-radius: 8px !important;
}

.border-radius-16 {
  border-radius: 16px !important;
}

.border-radius-20 {
  border-radius: 20px !important;
}

.padding-0 {
  padding: 0 !important;
}

.padding-16 {
  padding: 16px;
}

.pt-0 {
  padding-top: 0px !important;
}

.pt-4 {
  padding-top: 4px !important;
}

.pt-6 {
  padding-top: 6px !important;
}

.pt-8 {
  padding-top: 8px;
}

.pt-16 {
  padding-top: 16px;
}

.pt-32 {
  padding-top: 32px;
}

.pt-64 {
  padding-top: 64px;
}

.pr-4 {
  padding-right: 4px;
}

.pr-8 {
  padding-right: 8px;
}

.pr-16 {
  padding-right: 16px;
}

.pr-32 {
  padding-right: 32px;
}

.pr-64 {
  padding-right: 64px;
}

.pl-0 {
  padding-left: 0px;
}

.pl-4 {
  padding-left: 4px;
}

.pl-8 {
  padding-left: 8px;
}

.pl-16 {
  padding-left: 16px;
}

.pl-32 {
  padding-left: 32px;
}

.pb-0 {
  padding-bottom: 0px;
}

.pb-8 {
  padding-bottom: 8px;
}

.pb-16 {
  padding-bottom: 16px;
}

.pb-32 {
  padding-bottom: 32px;
}

.pb-64 {
  padding-bottom: 64px;
}

.pb-100 {
  padding-bottom: 100px;
}

.margin-0 {
  margin: 0px;
}

.margin-16 {
  margin: 16px;
}

.mt-min-16 {
  margin-top: -16px !important;
}

.mt-min-8 {
  margin-top: -8px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-4 {
  margin-top: 4px !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.mt-12 {
  margin-top: 12px !important;
}

.mt-16 {
  margin-top: 16px !important;
}

.mt-24 {
  margin-top: 24px !important;
}

.mt-32 {
  margin-top: 32px;
}

.mt-64 {
  margin-top: 64px;
}

.mb-min-32 {
  margin-bottom: -32px !important;
}

.mb-min-16 {
  margin-bottom: -16px !important;
}

.mb-min-8 {
  margin-bottom: -8px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-2 {
  margin-bottom: 2px;
}

.mb-6 {
  margin-bottom: 6px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-32 {
  margin-bottom: 32px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-64 {
  margin-bottom: 64px;
}

.mr-8 {
  margin-right: 8px;
}

.mr-32 {
  margin-right: 32px;
}

.ml-64 {
  margin-left: 64px;
}

.ml-32 {
  margin-left: 32px;
}

.ml-16 {
  margin-left: 16px;
}

.ml-12 {
  margin-left: 12px;
}

.ml-8 {
  margin-left: 8px;
}

.ml-4 {
  margin-left: 4px;
}

.fs-9 {
  font-size: 9px;
}

.fs-10 {
  font-size: 10px;
}

.fs-11 {
  font-size: 11px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-16 {
  font-size: 16px;
}

.fs-17 {
  font-size: 17px;
}

.fs-18 {
  font-size: 18px;
}

.fs-19 {
  font-size: 19px;
}

.fs-20 {
  font-size: 20px;
}

.fs-21 {
  font-size: 21px;
}

.fs-22 {
  font-size: 22px;
}

.fs-23 {
  font-size: 23px;
}

.fs-24 {
  font-size: 24px;
}

.fs-25 {
  font-size: 25px;
}

.fs-26 {
  font-size: 26px;
}

.fs-27 {
  font-size: 27px;
}

.fs-28 {
  font-size: 28px;
}

.font-style-italic {
  font-style: italic;
}

.font-weight-normal {
  font-weight: normal !important;
}

.font-weight-bold {
  font-weight: bold !important;
}

.line-height-12 {
  line-height: 1.2 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.icon-md {
  font-size: medium;
}

.sidebar {
  position: absolute;
  display: none;
}

.m-item {
  /* padding: 0 !important; */
  background-color: transparent !important;
}

.web-company:hover {
  color: #0076de !important;
}

@media only screen and (max-width: 575px) {
  .card-forgot-password {
    margin: 10px;
    max-width: 600px;
    padding: 20px 40px;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
  }
}

@media only screen and (min-width: 576px) {
  .card-forgot-password {
    margin: 80px;
    max-width: 600px;
    padding: 20px 40px;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
  }
}

@media only screen and (min-width: 768px) {
  .card-forgot-password {
    margin: 120px;
    max-width: 600px;
    padding: 20px 40px;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
  }
}

/* @media only screen and (max-width: 840px) {
  .breadcrumb-terms {
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding-bottom: 16px;
  }

  .card-terms {
    margin-left: 20px;
    margin-right: 20px;
    max-width: 800px;
    padding: 50px 50px;
    margin-bottom: 50px;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
  }
}

@media only screen and (min-width: 841px) {
  .breadcrumb-terms {
    margin-top: 60px;
    padding-bottom: 16px;
  }

  .card-terms {
    max-width: 800px;
    padding: 50px 50px;
    margin-bottom: 50px;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
  }
} */

.ant-progress-text {
  margin-left: 6px !important;
}

.bridge:hover {
  cursor: pointer;
  box-shadow: 1px 1px 8px #cccccc;
}

@media only screen and (max-width: 576px) {
  .application-date {
    float: left;
  }
}

@media only screen and (min-width: 576px) {
  .application-date {
    float: right;
  }
}

.fade-in {
  transition: opacity 1s ease;
}

.fade-out {
  opacity: 0;
  transition: opacity 1s ease;
}

.option-img:hover {
  opacity: 0.7;
}

@media only screen and (max-width: 575px) {
  .parallax-banner-layer-0 {
    background-size: contain !important;
    background-repeat: no-repeat !important;
    width: 100%;
    height: 'auto';
    margin-top: 100px;
  }
}

@media only screen and (min-width: 576px) {
  .parallax-banner-layer-0 {
    background-size: contain !important;
    background-repeat: no-repeat !important;
    width: 100%;
    height: 'auto';
    margin-top: 40px;
  }
}

@media only screen and (min-width: 1600px) {
  .parallax-banner-layer-0 {
    background-size: contain !important;
    background-repeat: no-repeat !important;
    width: 100%;
    height: 'auto';
  }
}

.collapse-container-custom {
  background-color: #f7f7f7 !important;
  border-radius: 8px !important;
  box-shadow: 1px 4px 8px #e6e6e6;
}

.collapse-panel-custom {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: none;
}

@media only screen and (max-width: 575px) {
  .collapse-panel-custom > .ant-collapse-header {
    width: 100%;
    color: #32ab6d !important;
    font-size: 16px !important;
    font-weight: bold;
  }
  
  .collapse-panel-custom > .ant-collapse-content {
    width: 100%;
    font-size: 14px !important;
  }
}

@media only screen and (min-width: 576px) {
  .collapse-panel-custom > .ant-collapse-header {
    width: 100%;
    color: #32ab6d !important;
    font-size: 18px !important;
    font-weight: bold;
  }
  
  .collapse-panel-custom > .ant-collapse-content {
    width: 100%;
    font-size: 14px !important;
  }
}

@media only screen and (min-width: 1600px) {
  .collapse-panel-custom > .ant-collapse-header {
    width: 100%;
    color: #32ab6d !important;
    font-size: 20px !important;
    font-weight: bold;
  }
  
  .collapse-panel-custom > .ant-collapse-content {
    width: 100%;
    font-size: 16px !important;
  }
}

/* .collapse-panel-custom > .ant-collapse-header > .ant-collapse-arrow {
  padding: 16px 16px !important;
} */

.collapse-panel-custom > .ant-collapse-header > .ant-collapse-arrow svg {
  width: 16px;
  height: 16px;
}

.checkbox-filter-job > .ant-checkbox {
  top: 0;
}

.checkbox-filter-job > .ant-checkbox-wrapper {
  display: flex;
  padding-top: 4px;
}

.xpert .item {
  border: 1px solid transparent;
}

.xpert .item:hover {
  border: 2px solid #32ab6d;
  cursor: pointer;
}

.xpert-hover {
  padding : 2;
  border: 2px solid #32ab6d;
  border-radius: 4px;
}

.bankAccount .item:hover {
  border: 1px solid #32ab6d;
  cursor: pointer;
}

.bankAccount-hover {
  padding : 2;
  border: 1px solid #32ab6d;
  border-radius: 4px;
}

@media only screen and (max-width: 575px) {
  .m-content {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
}

.faq-job-detail > .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  padding: 8px;
}

.language > .ant-select-item-option-content {
  white-space: normal !important;
}

/* Mobile */
@media only screen and (max-width: 575px) {
  .my-order-card {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .card-body-info-assessment {
    padding: 8px;
  }

  .card-body-question {
    padding: 0;
  }
}
 /* > Mobile */
@media only screen and (min-width: 576px) {
  .my-order-card {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }

  .card-body-info-assessment {
    padding: 64px;
  }

  .card-body-question {
    padding: 32px;
  }
}

.blue-tabs > .ant-tabs-card .ant-tabs-tab, .blue-tabs > .ant-tabs-card .ant-tabs-tab {
  color: #838383;
  margin: 0;
  padding: 8px 16px;
  background: #dae3f1;
  border: 1px solid #f0f0f0;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.blue-tabs > .ant-tabs-card .ant-tabs-tab-active, .blue-tabs > .ant-tabs-card .ant-tabs-tab-active  {
  background-color: #4272B9;
}

.blue-tabs > .ant-tabs-card.ant-tabs-top .ant-tabs-tab, .blue-tabs > .ant-tabs-card.ant-tabs-top .ant-tabs-tab {
  border-radius: 8px 8px 0 0;
}

.blue-tabs .ant-tabs-tab .ant-tabs-tab-btn {
  color: #838383;
}

.blue-tabs .ant-tabs-tab .ant-tabs-tab-btn:hover {
  color: #4272B9 !important;
}

.blue-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ffffff;
}

.blue-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn:hover {
  color: #ffffff !important;
}

.modal-border-rad-24 > .ant-modal-content {
  border-radius: 24px;
}

.modal-border-rad-6 > .ant-modal-content {
  border-radius: 6px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.upload-list-document .ant-upload-list-item .ant-upload-list-item-name {
  cursor: pointer;
}
