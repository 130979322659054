.outer {
  overflow: hidden;
}
.inner i {
  width: 40px;
  height: 40px;
  border: 1px solid #ffffff;
  border-radius: 50%;
  background-color: #ffffff;
}

.inner .top {
  margin-top: -20px;
}
.inner .bottom {
  margin-top: -20px;
  margin-bottom: -22px;
}
.inner .left {
  float: left;
  margin-left: -20px;
}
.inner .right {
  float: right;
  margin-right: -20px;
}

@media only screen and (min-width: 1600px) {
  .title-ticket {
    width: 97%;
    padding-left: 20px;
    padding-top: 18px;
  }
}
@media only screen and (min-width: 992px) {
  .title-ticket {
    width: 97%;
    padding-left: 20px;
  }
}

.m-title-ticket {
  width: 100%;
  padding: 16px;
}